import React, { useContext, useEffect } from "react";
import { RentContext } from "../../../contexts/RentContext";
import { domain } from "../../../config";

const RentPopup = ({ isOpen, setIsOpen, id, setPopup, setMsg }) => {
  const { rent, setRent } = useContext(RentContext);
  const token = localStorage.getItem("token");

  const handleNo = () => {
    setIsOpen(false);
  };
  const handleYes = (e) => {
    e.preventDefault();
    handleDelete();
  };

  useEffect(() => {
    const handleEnter = (e) => {
      if (e.key === "Enter") {
        handleDelete();
      } else if (e.key === "Escape") {
        handleNo();
      }
    };
    if (isOpen) {
      document.addEventListener("keydown", handleEnter);
    }

    return () => {
      document.removeEventListener("keydown", handleEnter);
    };
  }, [isOpen]);

  const handleDelete = async () => {
    try {
      const response = await fetch(`${domain}/rent/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(`${data.message}`);
      }
      setIsOpen(false);
      setPopup(true);
      setMsg("Rent Successfully Deleted");

      const newRent = rent.filter((d) => d._id !== id);
      setRent(newRent);
    } catch (error) {
      console.error("An error occurred:", error);
      setIsOpen(false);
      setPopup(true);
      setMsg(error.message);
    }
  };

  return (
    <>
      {isOpen && (
        <>
          <div id="popup" className="customer__popup">
            <form className="popup-content">
              <h2>Are you sure want to delete?</h2>
              <button id="confirmButton" onClick={handleYes} type="submit">
                Yes
              </button>
              <button id="cancelButton" onClick={handleNo}>
                No
              </button>
            </form>
          </div>
          <div id="overlay" className="customer__overlay"></div>
        </>
      )}
    </>
  );
};

export default RentPopup;
