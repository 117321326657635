import { createContext, useState } from "react";
export const CustomerContext = createContext({});

export function CustomerContextProvider({ children }) {
  const [customer, setCustomer] = useState([]);

  return (
    <CustomerContext.Provider value={{ customer, setCustomer }}>
      {children}
    </CustomerContext.Provider>
  );
}
