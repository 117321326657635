import React, { useContext, useState, useEffect } from "react";
import { ProductContext } from "../../../contexts/ProductContext";
import { domain } from "../../../config";
import { useNavigate } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
const ProductForm = ({ isOpen, setIsOpen, data, id, setPopup, setMsg }) => {
  const { product, setProduct } = useContext(ProductContext);
  const [displayName, setDisplayName] = useState("");
  const [deskripsi, setDeskripsi] = useState("");

  const [quantity, setQty] = useState(0);
  const [disabledUpdate, setDisabledUpdate] = useState(false);

  const [unit, setUnit] = useState("");
  const [rp, setRP] = useState("");
  const [formattedHargaSatuan, setFormattedHargaSatuan] = useState("");
  const [hargaSatuan, setHargaSatuan] = useState("");
  const [hargaSatuanAcc, setHargaSatuanAcc] = useState("");
  const nav = useNavigate();
  const token = localStorage.getItem("token");
  const payload = {
    displayName,
    deskripsi,
    quantity,
    unit,
    hargaSatuan: hargaSatuan,
  };
  const formatToRupiah = (value) => {
    // Remove non-numeric characters and convert to a number
    const numericValue = parseFloat(value.replace(/[^0-9.-]/g, ""));

    if (!isNaN(numericValue)) {
      const formattedValue = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(numericValue);

      return formattedValue;
    } else {
      return "";
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();

    if (data) {
      postData(payload, true);
      return;
    }
    postData(payload);

    clearData();
  };
  useEffect(() => {
    const original = data && {
      displayName: data.displayName,
      deskripsi: data.deskripsi,
      quantity: data.quantity,
      unit: data.unit,
      hargaSatuan: data.hargaSatuan,
    };
    const stringifiedObj1 = JSON.stringify(original);
    const stringifiedObj2 = JSON.stringify(payload);

    if (stringifiedObj1 == stringifiedObj2) {
      setDisabledUpdate(true);
    } else {
      setDisabledUpdate(false);
    }
  }, [payload]);

  useEffect(() => {
    if (data) {
      setIsOpen(true);
      setDeskripsi(data.deskripsi);
      setDisplayName(data.displayName);
      setQty(Number(data.quantity));
      setUnit(data.unit);
      setHargaSatuan(data.hargaSatuan);
      // setHargaSatuanAcc(data.hargaSatuanAcc);
    }
  }, [data]);

  const clearData = () => {
    setDisplayName("");
    setDeskripsi("");
    setQty("");
    setUnit("");
    setHargaSatuan("");
    setIsOpen(false);
  };

  const postData = async (data, update) => {
    try {
      const response = await fetch(
        `${update ? `${domain}/product/${id}` : `${domain}/product`}`,
        {
          method: `${update ? "PATCH" : "POST"}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const data = await response.json();
        console.log(data);
        throw new Error(`${data.message}`);
      }

      if (update) {
        const filteredProduct = product.map((c) => {
          if (c._id === data._id) {
            return data;
          }
          return c;
        });
        setProduct(filteredProduct);
        setPopup(true);
        setMsg("Successfully Update Product");

        // nav("/product");
        return;
      }

      const responseData = await response.json();
      console.log(responseData);
      setProduct([responseData, ...product]);
      setPopup(true);
      setMsg("Successfully Add Product");
    } catch (error) {
      console.error("An error occurred:", error.message);
      setPopup(true);
      setMsg(error.message);
    }
  };

  return (
    isOpen && (
      <>
        <form className="customer__form" onSubmit={handleForm}>
          {data && <h1>Update</h1>}
          <div className="customer__groupfield">
            <label className="customer__label">
              Display Name <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="customer__input"
              required
              onChange={(e) => setDisplayName(e.target.value)}
              value={displayName}
            ></input>
          </div>
          <div className="customer__groupfield">
            <label className="customer__label">Deskripsi</label>
            <textarea
              type="text"
              className="customer__input"
              required
              onChange={(e) => setDeskripsi(e.target.value)}
              value={deskripsi}
              style={{ height: "100px" }}
            ></textarea>
          </div>
          <div className="customer__groupfield">
            <label className="customer__label">Quantity</label>
            <input
              type="number"
              className="customer__input"
              required
              onChange={(e) => setQty(Number(e.target.value))}
              value={quantity}
            ></input>
          </div>
          <div className="customer__groupfield">
            <label className="customer__label">Unit</label>
            <input
              type="text"
              className="customer__input"
              onChange={(e) => setUnit(e.target.value)}
              value={unit}
              required
            ></input>
          </div>
          <div className="customer__groupfield">
            <label className="customer__label">
              Harga Satuan <span style={{ color: "red" }}>*</span>
            </label>

            <InputNumber
              inputId="currency-indonesia"
              value={hargaSatuan}
              onValueChange={(e) => {
                setHargaSatuan(e.value);
                console.log(hargaSatuan);
              }}
              mode="currency"
              currency="IDR"
              locale="de-DE"
            />
          </div>
          <span style={{ color: "gray", fontSize: "12px" }}>* required</span>
          <button
            type="submit"
            className="customer__button"
            disabled={disabledUpdate}
          >
            {data ? "Update" : "Submit"}
          </button>
        </form>

        {data && data.history && (
          <TabView>
            <TabPanel header="Activity">
              <DataTable value={data.history} size="small">
                <Column field={"action"} header={"Action"} />
                <Column
                  header={"Data"}
                  field={(e) => {
                    return e.updatedFields || "";
                  }}
                  body={(e) => {
                    const jsonData = e.updatedFields;

                    // Format the fields

                    const formattedString =
                      jsonData &&
                      Object.entries(jsonData).map(([key, value]) => (
                        <span key={key}>
                          <b>{key}</b>: {value},{" "}
                        </span>
                      ));

                    return <p className="m-0">{formattedString}</p>;
                  }}
                ></Column>
                <Column
                  body={(e) => {
                    return new Date(e.createdAt).toLocaleString();
                  }}
                  header={"Created"}
                />
              </DataTable>
            </TabPanel>
            <TabPanel header="Note"></TabPanel>
          </TabView>
        )}
      </>
    )
  );
};

export default ProductForm;
