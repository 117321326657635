import { ProductContext } from "../../../contexts/ProductContext";
import { UserContext } from "../../../contexts/UserContext";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { domain } from "../../../config";
import ProductAction from "../__action/ProductAction";
import Popup from "../../Popup/Popup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const ProductTable = ({ setIsOpen, isOpen }) => {
  const { product, setProduct } = useContext(ProductContext);
  const { user, setUser } = useContext(UserContext);
  const [isPopup, setPopup] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const token = localStorage.getItem("token");
  const [filter, setFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const nav = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setPopup(true);
      setInfoMsg("Loading");
      const data = await fetch(`${domain}/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await data.json();
      if (data.ok) {
        setPopup(false);
        setProduct(response.prodDoc);
      } else if (!data.ok) {
        if (
          response.message == "jwt expired" ||
          response.message == "jwt malformed"
        ) {
          console.log("error");
          setUser(false);
          nav("/login");
        }
      }
    };
    fetchData();
  }, []);

  const exportToExcel = () => {
    const fetchData = async () => {
      setPopup(true);
      setInfoMsg("Loading");

      try {
        const data = await fetch(`${domain}/product-excel`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
        });

        if (!data.ok) {
          const errorResponse = await data.json();
          if (
            errorResponse.message === "jwt expired" ||
            errorResponse.message === "jwt malformed"
          ) {
            console.log("Token expired");
            setUser(false);
            nav("/login");
            return;
          } else {
            console.error("Error in response:", errorResponse.message);
            setPopup(false);
            // Handle other error scenarios if needed
            return;
          }
        }

        const blob = await data.blob();
        console.log(blob);

        if (blob) {
          setPopup(false);
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "rents.xlsx";
          link.click();
          URL.revokeObjectURL(url);
        } else {
          console.error("Blob not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle other errors if needed
      }
    };

    fetchData();
  };

  return (
    <>
      <Popup
        isOpen={isPopup}
        setIsOpen={setPopup}
        infoMsg={infoMsg}
        setInfoMsg={setInfoMsg}
      />
      <div style={{ display: "flex", marginTop: "30px" }}>
        <Button
          // className="customer__button"
          style={{ marginRight: "10px", marginBottom: "10px" }}
          onClick={() => setIsOpen(!isOpen)}
          severity="secondary"
          size="small"
        >
          New Product
        </Button>
        <InputText
          placeholder="Keyword Search"
          style={{
            // marginLeft: "auto",
            display: "block",
            marginBottom: "10px",
          }}
          onInput={(e) => {
            setFilter({
              ...filter,
              global: {
                value: e.target.value,
                matchMode: FilterMatchMode.CONTAINS,
              },
            });
          }}
        />
        <Button
          style={{ marginBottom: "10px", marginLeft: 10 }}
          severity="success"
          onClick={() => exportToExcel()}
          size="small"
        >
          Export To Excel
        </Button>
      </div>
      <DataTable
        value={product}
        showGridlines
        filters={filter}
        dataKey="_id"
        tableStyle={{ minWidth: "70rem" }}
        paginator
        stripedRows
        rows={10}
        removableSort
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
      >
        <Column
          field="displayName"
          header="Display Name"
          sortable
          body={(product) => {
            return (
              <Link to={`/product/${product._id}`} className="customer__name">
                {product.displayName}
              </Link>
            );
          }}
        ></Column>
        <Column field="deskripsi" sortable header="Deskripsi"></Column>
        <Column field="quantity" sortable header="Quantity"></Column>
        <Column field="unit" sortable header="Unit"></Column>
        <Column
          field="hargaSatuan"
          body={(e) => {
            return new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(e.hargaSatuan);
          }}
          sortable
          header="Harga Satuan"
        ></Column>
        <Column
          field="createdAt"
          body={(e) => {
            return new Date(e.createdAt).toLocaleString();
          }}
          header="Created At"
          sortable
        ></Column>
        <Column
          header="Action"
          body={(d) => {
            return (
              <ProductAction
                id={d._id}
                key={d._id}
                isPopup={isPopup}
                setPopup={setPopup}
                setInfoMsg={setInfoMsg}
              />
            );
          }}
        ></Column>
      </DataTable>
    </>
  );
};
export default ProductTable;
