import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import LoginPopup from "./__popup/LoginPopup";
import { domain } from "../../config";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import Popup from "../Popup/Popup";
const Login = ({ path }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const { user, setUser } = useContext(UserContext);
  const nav = useNavigate();
  const location = useLocation();
  const navigate = useNavigate();
  const prevLocation = React.useRef(location);

  const login = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setInfoMsg("Loading");
    const data = {
      username,
      password,
    };

    const response = await fetch(`${domain}/login`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const dataLogin = await response.json();

    if (response.ok) {
      setUser(true);
      setIsLoading(false);
      localStorage.setItem("token", dataLogin.token);
      nav("/");
    } else if (!response.ok) {
      setIsOpen(true);
      setIsLoading(false);
      setErr(dataLogin.message);
    }
  };
  useEffect(() => {
    if (user) {
      nav("/");
    }
  }, [user]);

  return (
    <>
      <div className="login__wrapper">
        <Card>
          <form className="login__form" onSubmit={login}>
            <h1 style={{ textAlign: "center" }}>Login</h1>
            <div className="customer__groupfield">
              <label className="customer__label">Username</label>
              <InputText
                // className="customer__input"
                className="p-inputtext-sm"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                required
              ></InputText>
            </div>
            <div className="customer__groupfield">
              <label className="customer__label">Password</label>
              <Password
                // className="customer__input"
                type="password"
                className="p-inputtext-sm"
                value={password}
                required
                toggleMask
                feedback={false}
                onChange={(e) => setPassword(e.target.value)}
              ></Password>
            </div>
            <Button label="Login" severity="info" size="small" type="submit" />
          </form>
        </Card>
      </div>
      <Popup isOpen={isLoading} setIsOpen={setIsLoading} infoMsg={infoMsg} />
      <LoginPopup isOpen={isOpen} setIsOpen={setIsOpen} err={err} />
    </>
  );
};

export default Login;
