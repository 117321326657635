import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import { CustomerContextProvider } from "./contexts/CustomerContext";
import { UserContextProvider } from "./contexts/UserContext";
import { ProductContextProvider } from "./contexts/ProductContext";
import { RentContextProvider } from "./contexts/RentContext";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CustomerContextProvider>
      <UserContextProvider>
        <RentContextProvider>
          <ProductContextProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ProductContextProvider>
        </RentContextProvider>
      </UserContextProvider>
    </CustomerContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
