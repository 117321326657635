import React, { useState, useContext, useEffect } from "react";
import { RentContext } from "../../../contexts/RentContext";

import { addLocale } from "primereact/api";
import { domain } from "../../../config";
import { Calendar } from "primereact/calendar";
import Select from "react-select";
import CustomerForm from "../../Customer/__form/CustomerForm";
import { InputNumber } from "primereact/inputnumber";
import AddProduct from "../../AddProduct/AddProduct";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { Button } from "primereact/button";
const RentForm = ({ isOpen, setIsOpen, data, id, setMsg, setPopup }) => {
  const { customer, setCustomer } = useContext(CustomerContext);
  const { rent, setRent } = useContext(RentContext);

  const [valid, setValid] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  let [total, setTotal] = useState();
  const [grandTotal, setGrandTotal] = useState("");
  const [productList, setProductList] = useState([
    {
      productID: "",
      label: "",
      quantity: "",
      hargaSatuan: "",
      total: "",
    },
  ]);
  const [customerID, setCustomerID] = useState("");
  const [customerLabel, setCustomerLabel] = useState("");
  const [deposit, setDeposit] = useState();
  const [tglTr, setTglTr] = useState("");
  const [tglTake, setTglTake] = useState("");
  const [tglOut, setTglOut] = useState("");
  const token = localStorage.getItem("token");
  const payload = {
    customer: customerID,
    product: productList,
    deposit,
    total,
    grandTotal,
    tglTr,
    tglTake,
    tglOut,
  };
  const handleForm = async (e) => {
    e.preventDefault();

    if (data) {
      postData(payload, true);
      return;
    }
    postData(payload);

    clearData();
  };
  useEffect(() => {
    setGrandTotal(deposit + total);
  }, [deposit, total]);

  useEffect(() => {
    if (productList) {
      let totalSum = productList.reduce((accumulator, product) => {
        return accumulator + product.total;
      }, 0);
      setTotal(totalSum);
    }
  }, [productList]);

  function formatISODateForDatetimeLocal(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to the month since it's 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  useEffect(() => {
    if (data) {
      setIsOpen(true);
      setCustomerID(data.customer._id);
      setCustomerLabel(data.customer.displayName);
      setProductList(
        data.product.map((p) => ({
          productID: p.productID._id,
          label: p.productID.displayName,
          quantity: p.quantity,
          hargaSatuan: p.hargaSatuan,
          total: p.total,
          grandTotal: p.grandTotal,
        }))
      );
      setDeposit(data.deposit);
      setGrandTotal(data.grandTotal);
      setTglTr(new Date(formatISODateForDatetimeLocal(data.tglTr)));
      setTglTake(new Date(formatISODateForDatetimeLocal(data.tglTake)));
      setTglOut(new Date(formatISODateForDatetimeLocal(data.tglOut)));
    }
  }, [data]);

  const clearData = () => {
    setCustomerID("");
    setProductList("");
    setDeposit("");
    setTotal();
    setIsOpen(false);
  };

  const postData = async (data, update) => {
    try {
      const response = await fetch(
        `${update ? `${domain}/rent/${id}` : `${domain}/rent`}`,
        {
          method: `${update ? "PATCH" : "POST"}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const data = await response.json();

        throw new Error(`${data.message}`);
      }

      if (update) {
        const rentFiltered = rent.map((r) => {
          if (r._id === data._id) {
            return data;
          }
          return r;
        });
        setRent(rentFiltered);

        setPopup(true);
        setMsg("Rent Succesfully Updated");
        return;
      }

      const responseData = await response.json();
      console.log(responseData);
      setRent([, responseData, ...rent]);
      setPopup(true);
      setMsg("Successfully Add Rent");
    } catch (error) {
      console.error("An error occurred:", error.message);
      setPopup(true);
      setMsg(error.message);
    }
  };

  useEffect(() => {
    if (productList.length == 0) {
      setValid(true);
    } else if (productList.length > 0) {
      productList.map((p) => {
        if (!p.quantity || !p.total) {
          setValid(true);
        } else if (customerID == "") {
          setValid(true);
        } else if (deposit < 0) {
          setValid(true);
        } else {
          setValid(false);
        }
      });
    }
  }, [productList, customerID, deposit]);

  const handleAddElement = () => {
    setProductList([
      ...productList,
      { productID: "", quantity: "", label: "" },
    ]);
  };

  return (
    isOpen && (
      <>
        <CustomerForm
          isOpen={isPopupOpen}
          setIsOpen={setIsPopupOpen}
          data={null}
        ></CustomerForm>
        <form className="customer__form" onSubmit={handleForm}>
          {data && <h1>Update</h1>}
          <h1>Customer</h1>
          <button
            className="customer__button"
            onClick={() => setIsPopupOpen(!isPopupOpen)}
            type="button"
          >
            New Customer
          </button>
          <label>Choose Customer</label>
          <Select
            options={customer.map((c) => ({
              value: c._id,
              label: c.displayName,
            }))}
            value={{
              value: customerID,
              label: customerLabel,
            }}
            onChange={(e) => {
              setCustomerID(e.value);
              setCustomerLabel(e.label);
            }}
          />
          <h1>Product</h1>

          <AddProduct
            productList={productList}
            setProductList={setProductList}
            handleAddElement={handleAddElement}
          />
          <div className="customer__groupfield">
            <label className="customer__label">Tanggal Transaksi</label>

            <Calendar
              value={tglTr}
              className="customer__input"
              onChange={(e) => {
                setTglTr(e.target.value);
              }}
              showButtonBar
              showIcon
              required
              dateFormat="dd/mm/yy"
            ></Calendar>
          </div>
          <div className="customer__groupfield">
            <label className="customer__label">Tanggal Pengambilan</label>

            <Calendar
              value={tglTake}
              className="customer__input"
              onChange={(e) => {
                setTglTake(e.target.value);
              }}
              showButtonBar
              showIcon
              required
              dateFormat="dd/mm/yy"
            ></Calendar>
          </div>
          <div className="customer__groupfield">
            <label className="customer__label">Tanggal Pengembalian</label>

            <Calendar
              value={tglOut}
              className="customer__input"
              onChange={(e) => {
                setTglOut(e.target.value);
              }}
              showButtonBar
              showIcon
              required
              dateFormat="dd/mm/yy"
            ></Calendar>
          </div>
          <div className="customer__groupfield">
            <label className="customer__label">Total</label>
            <InputNumber
              inputId="currency-indonesia"
              className="customer__input"
              value={total}
              readOnly
              mode="currency"
              currency="IDR"
              locale="de-DE"
            />
          </div>
          <div className="customer__groupfield">
            <label className="customer__label">Deposit</label>
            <InputNumber
              inputId="currency-indonesia"
              value={deposit}
              className="customer__input"
              onValueChange={(e) => {
                setDeposit(e.target.value);
              }}
              mode="currency"
              currency="IDR"
              locale="de-DE"
            />
          </div>
          <div className="customer__groupfield">
            <label className="customer__label">Grand Total</label>
            <InputNumber
              className="customer__input"
              inputId="currency-indonesia"
              value={grandTotal}
              onValueChange={(e) => {
                setGrandTotal(e.target.value);
              }}
              mode="currency"
              currency="IDR"
              locale="de-DE"
            />
          </div>
          <span style={{ color: "gray", fontSize: "12px" }}>* required</span>
          <Button
            type="submit"
            className="customer__button"
            disabled={valid}
            severity="secondary"
            label={data ? "Update" : "Submit"}
          ></Button>
        </form>
      </>
    )
  );
};

export default RentForm;
