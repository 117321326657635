import React, { useState, useContext, useEffect } from "react";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";
import { domain } from "../../../config";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
const CustomerForm = ({ isOpen, setIsOpen, data, id, setPopup, setMsg }) => {
  const { customer, setCustomer } = useContext(CustomerContext);
  const [displayName, setDisplayName] = useState("");
  const { user, setUser } = useContext(UserContext);
  const [typeCustomer, setType] = useState("Individual");
  const [disabledUpdate, setDisabledUpdate] = useState(false);
  const type = [
    { name: "Individual", code: "NY" },
    { name: "Company", code: "RM" },
  ];
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const nav = useNavigate();
  const token = localStorage.getItem("token");
  const payload = {
    displayName,
    typeCustomer,
    phone,
    address,
  };
  const handleForm = async (e) => {
    e.preventDefault();

    if (data) {
      postData(payload, true);
      return;
    }
    postData(payload);

    clearData();
  };

  useEffect(() => {
    if (data) {
      setIsOpen(true);
      setPhone(data.phone);
      setDisplayName(data.displayName);
      setType(data.typeCustomer);
      setAddress(data.address);
    }
  }, [data]);
  useEffect(() => {
    const original = data && {
      displayName: data.displayName,
      typeCustomer: data.typeCustomer,
      phone: data.phone,
      address: data.address,
    };
    const stringifiedObj1 = JSON.stringify(original);
    const stringifiedObj2 = JSON.stringify(payload);

    if (stringifiedObj1 == stringifiedObj2) {
      setDisabledUpdate(true);
    } else {
      setDisabledUpdate(false);
    }
  }, [payload]);

  const clearData = () => {
    setDisplayName("");
    setType("Individual");
    setPhone("");
    setAddress("");
    setIsOpen(false);
  };

  const postData = async (data, update) => {
    try {
      const response = await fetch(
        `${update ? `${domain}/customer/${id}` : `${domain}/customer`}`,
        {
          method: `${update ? "PATCH" : "POST"}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        if (
          errorResponse.message === "jwt expired" ||
          errorResponse.message === "jwt malformed"
        ) {
          console.log("Token expired");
          setUser(false);
          nav("/login");
          return;
        }
        throw new Error(`${errorResponse.message}`);
      }

      if (update) {
        const filteredCustomer = customer.map((c) => {
          if (c._id === data._id) {
            return data;
          }
          return c;
        });
        setCustomer(filteredCustomer);
        setPopup(true);
        setMsg("Successfully Update Customer");

        return;
      }

      const responseData = await response.json();
      console.log(responseData);
      setCustomer([responseData, ...customer]);
      setPopup(true);
      setMsg("Successfully Add Customer");
    } catch (error) {
      console.error("An error occurred:", error.message);
      setPopup(true);
      setMsg(error.message);
    }
  };

  return (
    isOpen && (
      <>
        <form
          className="customer__form"
          onSubmit={handleForm}
          style={{ marginBottom: "30px" }}
        >
          {data && <h1>Update</h1>}
          {!data && (
            <Button
              icon="pi pi-times"
              severity="danger"
              aria-label="Cancel"
              style={{
                position: "absolute",
                top: 10,
                right: 15,
              }}
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(!isOpen);
              }}
            />
          )}

          <div className="customer__groupfield">
            <label className="customer__label">
              Display Name <span style={{ color: "red" }}>*</span>
            </label>

            <InputText
              className="p-inputtext-sm"
              placeholder="Nama Lengkap"
              style={{ width: 300 }}
              value={displayName}
              required
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </div>
          <div className="customer__groupfield">
            <label className="customer__label">
              Type Customer <span style={{ color: "red" }}>*</span>
            </label>

            <Dropdown
              value={typeCustomer}
              className="p-inputtext-sm"
              style={{ width: 300 }}
              onChange={(e) => setType(e.value.name)}
              options={type}
              optionLabel="name"
              editable
              placeholder="Select Customer Type"
            />
          </div>
          <div className="customer__groupfield">
            <label className="customer__label">
              Phone <span style={{ color: "red" }}>*</span>
            </label>

            <InputMask
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              mask="62?999999999999"
              className="p-inputtext-sm"
              style={{ width: 300 }}
            />
          </div>
          <div className="customer__groupfield">
            <label className="customer__label">Address</label>
            <InputTextarea
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              rows={5}
              cols={30}
              required
              style={{ width: 300 }}
              placeholder="Alamat"
            />
          </div>
          <span style={{ color: "gray", fontSize: "12px" }}>* required</span>
          <Button
            type="submit"
            className="customer__button"
            severity="secondary"
            label={data ? "Update" : "Submit"}
            disabled={disabledUpdate}
          ></Button>
        </form>

        {data && data.history && (
          <TabView>
            <TabPanel header="Activity">
              <DataTable value={data.history} size="small">
                <Column field={"action"} header={"Action"} />
                <Column
                  header={"Data"}
                  field={(e) => {
                    return e.updatedFields;
                  }}
                  body={(e) => {
                    const jsonData = e.updatedFields || "";

                    // Format the fields
                    const formattedString = Object.entries(jsonData).map(
                      ([key, value]) => (
                        <span key={key}>
                          <b>{key}</b>: {value},{" "}
                        </span>
                      )
                    );

                    return <p className="m-0">{formattedString}</p>;
                  }}
                ></Column>
                <Column
                  body={(e) => {
                    return new Date(e.createdAt).toLocaleString();
                  }}
                  header={"Created"}
                />
              </DataTable>
            </TabPanel>
            <TabPanel header="Note"></TabPanel>
          </TabView>
        )}
      </>
    )
  );
};

export default CustomerForm;
