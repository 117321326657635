import React, { useEffect } from "react";

const LoginPopup = ({ isOpen, err, setIsOpen }) => {
  useEffect(() => {
    const handleEnter = (e) => {
      if (e.key === "Escape") {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("keydown", handleEnter);
    }

    return () => {
      document.removeEventListener("keydown", handleEnter);
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <>
          <div id="popup" className="customer__popup">
            <form
              className="popup-content"
              onSubmit={(e) => {
                e.preventDefault();
                setIsOpen(false);
              }}
            >
              <h2>{err} </h2>
              <button type="submit">close</button>
            </form>
          </div>
          <div id="overlay" className="customer__overlay"></div>
        </>
      )}
    </>
  );
};

export default LoginPopup;
