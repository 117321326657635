import React, { useContext, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { ProductContext } from "../../../contexts/ProductContext";
import { AiFillEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import ProductPopup from "../__popup/ProductPopup";
const ProductAction = ({ id, isPopup, setPopup, setInfoMsg }) => {
  const { product, setProduct } = useContext(ProductContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <span>
      <ProductPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={id}
        isPopup={isPopup}
        setPopup={setPopup}
        setMsg={setInfoMsg}
      />
      <AiFillDelete
        style={{ cursor: "pointer" }}
        onClick={(e) => setIsOpen(true)}
      />
      <Link to={`update/${id}`}>
        <AiFillEdit style={{ cursor: "pointer" }} />
      </Link>
    </span>
  );
};

export default ProductAction;
