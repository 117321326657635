import React, { useState, useContext, useEffect } from "react";

import { domain } from "../../config";
import { RentContext } from "../../contexts/RentContext";
const Popup = ({
  isOpen,
  setIsOpen,
  infoMsg,
  setInfoMsg,
  isPembayaran,
  id,
  value,
  setIsPembayaran,
  isPengembalian,
  setIsPengembalian,
}) => {
  const token = localStorage.getItem("token");
  const { rent, setRent } = useContext(RentContext);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleNo = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleEnter = (e) => {
      if (e.key === "Escape") {
        handleNo();
      }
    };
    if (isOpen) {
      document.addEventListener("keydown", handleEnter);
    }

    return () => {
      document.removeEventListener("keydown", handleEnter);
    };
  }, [isOpen]);

  const updatePembayaran = async () => {
    let response;
    if (isPembayaran) {
      response = await fetch(`${domain}/rent/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },

        body: JSON.stringify({
          paymentStatus: value,
        }),
      });
    } else if (isPengembalian) {
      response = await fetch(`${domain}/rent/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },

        body: JSON.stringify({
          statusPengembalian: value,
        }),
      });
    }

    const data = await response.json();
    console.log(data);
    if (!response.ok) {
      throw new Error(`${data.message}`);
    }
    if (isPembayaran) {
      const rentFiltered = rent.map((r) => {
        if (r._id === data._id) {
          return data;
        }
        return r;
      });
      setRent(rentFiltered);
    } else if (isPengembalian) {
      const newRent = rent.filter((d) => d._id !== id);
      setRent(newRent);
    }

    setIsOpen(false);
    setIsSubmit(false);
    if (isPembayaran) {
      setIsPembayaran({
        status: false,
        id: "",
        value: "",
      });
    } else if (isPengembalian) {
      setIsPengembalian({
        status: false,
        id: "",
        value: "",
      });
    }
  };

  useEffect(() => {
    if (isSubmit) {
      updatePembayaran();
    }
  }, [isSubmit]);

  return (
    <>
      {isOpen && (
        <>
          <div id="popup" className="customer__popup">
            <form className="popup-content">
              {!isPembayaran && !isPengembalian && (
                <>
                  <h2>{infoMsg}</h2>
                  {infoMsg == "Loading" ? (
                    ""
                  ) : (
                    <button id="cancelButton" onClick={handleNo}>
                      Close
                    </button>
                  )}
                </>
              )}
              {isPembayaran && (
                <>
                  <h1 key={isPembayaran.id}>
                    Terima Pembayaran dengan {value}
                  </h1>
                  <button
                    id="cancelButton"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsSubmit(true);
                    }}
                  >
                    Terima
                  </button>
                  <button id="cancelButton" onClick={handleNo}>
                    Close
                  </button>
                </>
              )}
              {isPengembalian && (
                <>
                  <h1 key={isPengembalian.id}>Terima Pengembalian barang</h1>
                  <button
                    id="cancelButton"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsSubmit(true);
                    }}
                  >
                    Terima
                  </button>
                  <button id="cancelButton" onClick={handleNo}>
                    Close
                  </button>
                </>
              )}
            </form>
          </div>
          <div id="overlay" className="customer__overlay"></div>
        </>
      )}
    </>
  );
};

export default Popup;
