import React, { useContext, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { domain } from "../../config";

const MainContent = () => {
  const { user, setUser } = useContext(UserContext);
  const nav = useNavigate();

  useEffect(() => {
    if (!user) {
      nav("/login");
    }
  }, [user]);

  return (
    <div className="content-wrapper">
      <Sidebar />
      <Outlet className="content" />
    </div>
  );
};

export default MainContent;
