import React, { useState, useEffect, useContext } from "react";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { Link, useNavigate } from "react-router-dom";
import { domain } from "../../../config";
import { UserContext } from "../../../contexts/UserContext";
import CustomerAction from "../__action/CustomerAction";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Popup from "../../Popup/Popup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";

const CustomerTable = ({ setIsOpen, isOpen }) => {
  const { customer, setCustomer } = useContext(CustomerContext);
  const { user, setUser } = useContext(UserContext);
  const [isPopup, setPopup] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const token = localStorage.getItem("token");
  const [filter, setFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const nav = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setPopup(true);
      setInfoMsg("Loading");
      const data = await fetch(`${domain}/customer`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await data.json();
      if (data.ok) {
        setPopup(false);
        setCustomer(response.cust);
      } else if (!data.ok) {
        if (
          response.message == "jwt expired" ||
          response.message == "jwt malformed"
        ) {
          console.log("error");
          setUser(false);
          nav("/login");
        }
      }
    };
    fetchData();
  }, []);

  const exportToExcel = () => {
    const fetchData = async () => {
      setPopup(true);
      setInfoMsg("Loading");

      try {
        const data = await fetch(`${domain}/customer-excel`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
        });

        if (!data.ok) {
          const errorResponse = await data.json();
          if (
            errorResponse.message === "jwt expired" ||
            errorResponse.message === "jwt malformed"
          ) {
            console.log("Token expired");
            setUser(false);
            nav("/login");
            return;
          } else {
            console.error("Error in response:", errorResponse.message);
            setPopup(false);
            // Handle other error scenarios if needed
            return;
          }
        }

        const blob = await data.blob();
        console.log(blob);

        if (blob) {
          setPopup(false);
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "rents.xlsx";
          link.click();
          URL.revokeObjectURL(url);
        } else {
          console.error("Blob not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle other errors if needed
      }
    };

    fetchData();
  };

  return (
    <div style={{ marginBottom: "30px" }}>
      <Popup
        isOpen={isPopup}
        setIsOpen={setPopup}
        infoMsg={infoMsg}
        setInfoMsg={setInfoMsg}
      />
      <div style={{ display: "flex" }}>
        <Button
          style={{ marginBottom: "10px", marginRight: "10px" }}
          severity="secondary"
          onClick={() => setIsOpen(!isOpen)}
          size="small"
        >
          New Customer
        </Button>
        <InputText
          placeholder="Keyword Search"
          style={{
            // marginLeft: "auto",
            display: "block",
            marginBottom: "10px",
          }}
          onInput={(e) => {
            setFilter({
              ...filter,
              global: {
                value: e.target.value,
                matchMode: FilterMatchMode.CONTAINS,
              },
            });
          }}
        />
        <Button
          style={{ marginBottom: "10px", marginLeft: 10 }}
          severity="success"
          onClick={() => exportToExcel()}
          size="small"
        >
          Export To Excel
        </Button>
      </div>
      <DataTable
        value={customer}
        showGridlines
        stripedRows
        filters={filter}
        dataKey="_id"
        tableStyle={{ minWidth: "70rem" }}
        paginator
        rows={10}
        removableSort
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
      >
        <Column
          field="displayName"
          header="Display Name"
          sortable
          body={(customer) => {
            return (
              <Link to={`/${customer._id}`} className="customer__name">
                {customer.displayName}
              </Link>
            );
          }}
        ></Column>
        <Column field="typeCustomer" sortable header="Type Customer"></Column>
        <Column field="phone" sortable header="Phone"></Column>
        <Column field="address" sortable header="Address"></Column>
        <Column
          field="createdAt"
          body={(e) => {
            return new Date(e.createdAt).toLocaleString();
          }}
          header="Created At"
          sortable
        ></Column>
        <Column
          header="Action"
          body={(d) => {
            return (
              <CustomerAction
                id={d._id}
                key={d._id}
                isPopup={isPopup}
                setPopup={setPopup}
                setInfoMsg={setInfoMsg}
              />
            );
          }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default CustomerTable;
