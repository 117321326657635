import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomerForm from "../__form/CustomerForm";
import Popup from "../../Popup/Popup";
import { domain } from "../../../config";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const CustomerDetail = () => {
  let { id, update } = useParams();
  const [isPopup, setPopup] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const token = localStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState("");

  const historyProps =
    data.history && data.history.map((h) => Object.values(h));

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`${domain}/customer/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await data.json();
      setData(response);
    };
    fetchData();
  }, []);

  return (
    <div className="customer__wrapper">
      {!update && (
        <div>
          <h1>Detail</h1>
          <p>Display Name : {data.displayName}</p>
          <p>Type Customer : {data.typeCustomer}</p>
          <p>Phone : {data.phone}</p>
          <p>Address : {data.address}</p>
          <TabView>
            <TabPanel header="Activity">
              <DataTable value={data.history} size="small">
                <Column field={"action"} header={"Action"} />
                <Column
                  header={"Data"}
                  field={(e) => {
                    return e.updatedFields;
                  }}
                  body={(e) => {
                    const jsonData = e.updatedFields || "";

                    // Format the fields
                    const formattedString = Object.entries(jsonData).map(
                      ([key, value]) => (
                        <span key={key}>
                          <b>{key}</b>: {value},{" "}
                        </span>
                      )
                    );

                    return <p className="m-0">{formattedString}</p>;
                  }}
                ></Column>
                <Column
                  body={(e) => {
                    return new Date(e.createdAt).toLocaleString();
                  }}
                  header={"Created"}
                />
              </DataTable>
            </TabPanel>
            <TabPanel header="Note"></TabPanel>
          </TabView>
        </div>
      )}

      {update && (
        <CustomerForm
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setPopup={setPopup}
          setMsg={setInfoMsg}
          data={data}
          id={id}
        />
      )}
      <Popup isOpen={isPopup} setIsOpen={setPopup} infoMsg={infoMsg} />
    </div>
  );
};

export default CustomerDetail;
