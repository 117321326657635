import React, { useState, useEffect } from "react";
import Popup from "../../Popup/Popup";
import RentForm from "../__form/RentForm";
import { useParams } from "react-router-dom";
import { domain } from "../../../config";

import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
const RentDetail = () => {
  let { id, update } = useParams();
  const [isPopup, setPopup] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const token = localStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`${domain}/rent/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await data.json();
      setData(response);
    };
    fetchData();
  }, []);
  const renderArray = (arr) => (
    <ul>
      {arr.map((item, index) => (
        <li key={index}>{JSON.stringify(item)}</li>
      ))}
    </ul>
  );

  return (
    <div className="customer__wrapper">
      {!update && data && (
        <div>
          <h1>Detail</h1>
          <p>Rent: {data.rentNo}</p>
          <p>Customer: {data.customer.displayName}</p>
          <p>Product:</p>
          <table className="customer__table" border={"1px"}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Deskripsi</th>
                <th>Harga Jual</th>
                <th>Quantity</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {data.product.map((p, index) => {
                return (
                  <tr key={p.index}>
                    <td style={{ padding: "20px" }}>
                      {p.productID.displayName}
                    </td>
                    <td>{p.productID.deskripsi}</td>
                    <td>
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      }).format(p.hargaSatuan)}
                    </td>
                    <td>{p.quantity}</td>
                    <td>
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      }).format(p.total)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            className="customer__groupfield"
            style={{ marginTop: "20px", justifyContent: "flex-end" }}
          >
            <label className="customer__label">Deposit: </label>
            <input
              readOnly
              className="customer__input"
              value={new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(data.deposit)}
            />
          </div>
          <div
            className="customer__groupfield"
            style={{
              marginTop: "20px",

              justifyContent: "flex-end",
            }}
          >
            <label className="customer__label">Total: </label>
            <input
              readOnly
              className="customer__input"
              value={new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(data.total)}
            />
          </div>
          <div
            className="customer__groupfield"
            style={{
              marginTop: "20px",

              justifyContent: "flex-end",
            }}
          >
            <label className="customer__label">Grand Total: </label>
            <input
              readOnly
              className="customer__input"
              value={new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(data.grandTotal)}
            />
          </div>

          <TabView>
            <TabPanel header="Activity">
              <DataTable
                value={data.history}
                emptyMessage="No history found"
                size="small"
              >
                <Column field="action" header="Action" />
                <Column
                  header="Data"
                  body={(rowData, rowIndex) => {
                    const jsonData = rowData.updatedFields;
                    const formattedString =
                      jsonData &&
                      Object.entries(jsonData).map(([key, value], index) => (
                        <div key={index}>
                          <b>{key}</b>:{" "}
                          {Array.isArray(value) ? renderArray(value) : value}
                        </div>
                      ));

                    return <div className="m-0">{formattedString}</div>;
                  }}
                />
                <Column
                  body={(rowData) =>
                    new Date(rowData.createdAt).toLocaleString()
                  }
                  header="Created"
                />
              </DataTable>
            </TabPanel>
            <TabPanel header="Note"></TabPanel>
          </TabView>
        </div>
      )}

      {update && data && (
        <RentForm
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setPopup={setPopup}
          setMsg={setInfoMsg}
          data={data}
          id={id}
        />
      )}
      <Popup isOpen={isPopup} setIsOpen={setPopup} infoMsg={infoMsg} />
    </div>
  );
};

export default RentDetail;
