import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { domain } from "../../../config";
import { HiOutlineDocumentText } from "react-icons/hi";
import { AiFillEdit, AiOutlineWhatsApp } from "react-icons/ai";
import { Link } from "react-router-dom";

import RentPopup from "../__popup/RentPopup";

const RentAction = ({
  id,
  isPopup,
  setPopup,
  setInfoMsg,
  phone,
  customer,
  total,
  rentNo,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <span>
      <RentPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={id}
        isPopup={isPopup}
        setPopup={setPopup}
        setMsg={setInfoMsg}
      />
      <AiFillDelete
        style={{ cursor: "pointer" }}
        onClick={(e) => setIsOpen(true)}
      />
      <Link to={`update/${id}`}>
        <AiFillEdit style={{ cursor: "pointer" }} />
      </Link>
      <Link to={`${domain}/rent-pdf?id=${id}`} target="_blank">
        <HiOutlineDocumentText />
      </Link>
      <Link
        to={`https://api.whatsapp.com/send?phone=${phone}&text=Halo ${customer}, terima kasih atas kepercayaan Anda telah bertransaksi dengan butik kami. Berikut adalah kwitansi ${rentNo} sebesar ${total} Silakan lihat detail kwitansi di sini ${domain}/rent-pdf?id=${id} ...`}
        target="_blank"
      >
        <AiOutlineWhatsApp />
      </Link>
    </span>
  );
};

export default RentAction;
