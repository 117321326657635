import "./App.css";
import React, { useContext, useEffect, useState } from "react";
import { domain } from "./config";
import { UserContext } from "./contexts/UserContext";
import Customer from "./component/Customer/Customer";
import CustomerDetail from "./component/Customer/__detail/CustomerDetail";
import Login from "./component/Login/Login";
import MainContent from "./component/MainContent/MainContent";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import Product from "./component/Product/Product";
import ProductDetail from "./component/Product/__detail/ProductDetail";
import Rent from "./component/Rent/Rent";
import RentDetail from "./component/Rent/__detail/RentDetail";
import Return from "./component/Return/Return";

function App() {
  const { user, setUser } = useContext(UserContext);
  const token = localStorage.getItem("token");
  const { product } = useParams();
  const fetchUser = async (token) => {
    const response = await fetch(`${domain}/verify`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    if (data.status == "authenticated") {
      setUser(data);

      return;
    } else {
      console.log("false");
      setUser(false);
    }
  };
  useEffect(() => {
    if (token) {
      fetchUser(token);
    }
  }, [token]);

  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route
        path="/"
        element={<MainContent path={"/"} />}
        isAuthenticated={user}
        redirectPath="/login"
      >
        <Route index element={<Customer />} />
        <Route path=":id" element={<CustomerDetail />} />
        <Route path=":update/:id" element={<CustomerDetail />} />
      </Route>
      <Route path="/rent" element={<MainContent />}>
        <Route index element={<Rent />}></Route>
        <Route path=":id" element={<RentDetail />} />
        <Route path=":update/:id" element={<RentDetail />} />
      </Route>
      <Route path="/product" element={<MainContent />}>
        <Route index element={<Product />} />
        <Route path=":id" element={<ProductDetail />} />
        <Route path=":update/:id" element={<ProductDetail />} />
      </Route>
      <Route path="/return" element={<MainContent />}>
        <Route index element={<Return />} />
        <Route path=":id" element={<ProductDetail />} />
        <Route path=":update/:id" element={<ProductDetail />} />
      </Route>
      <Route path="*" element={<h1>Test</h1>} />
    </Routes>
  );
}

export default App;
