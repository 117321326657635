import React, { useEffect, useContext } from "react";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { UserContext } from "../../../contexts/UserContext";
import { domain } from "../../../config";
import { useNavigate } from "react-router-dom";

const FetchCustomer = () => {
  const nav = useNavigate();
  const token = localStorage.getItem("token");
  const { customer, setCustomer } = useContext(CustomerContext);
  const { user, setUser } = useContext(UserContext);
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`${domain}/customer`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await data.json();
      if (data.ok) {
        setCustomer(response.cust);
      } else if (!data.ok) {
        if (response.message == "jwt expired") {
          console.log("error");
          setUser(false);
          nav("/login");
        }
      }
    };
    fetchData();
  }, []);

  return <></>;
};

export default FetchCustomer;
