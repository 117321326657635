import React, { useState } from "react";
import CustomerForm from "./__form/CustomerForm";
import CustomerTable from "./__table/CustomerTable";
import Popup from "../Popup/Popup";
import LineChart from "../LineChart/LineChart";

const Customer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  return (
    <div className="customer__wrapper">
      <h1 className="customer__title">Customer</h1>
      <LineChart />

      <CustomerForm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setPopup={setPopup}
        setMsg={setInfoMsg}
        data={null}
      ></CustomerForm>
      <CustomerTable setIsOpen={setIsOpen} isOpen={isOpen} />
      <Popup isOpen={isPopup} setIsOpen={setPopup} infoMsg={infoMsg} />
    </div>
  );
};

export default Customer;
