import { createContext, useState } from "react";
export const RentContext = createContext({});

export function RentContextProvider({ children }) {
  const [rent, setRent] = useState([]);

  return (
    <RentContext.Provider value={{ rent, setRent }}>
      {children}
    </RentContext.Provider>
  );
}
