import React, { useRef, useEffect, useContext, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie, getDatasetAtEvent, getElementAtEvent } from "react-chartjs-2";
import { domain } from "../../config";
import { Link, useNavigate } from "react-router-dom";

import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
} from "chart.js";
import { UserContext } from "../../contexts/UserContext";
import { Line } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
const LineChart = () => {
  const chartRef = useRef();
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");
  const { setUser } = useContext(UserContext);
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`${domain}/customer/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await data.json();
      if (data.ok) {
        setData(response.month);
      } else if (!data.ok) {
        if (response.message == "jwt expired") {
          console.log("error");
          setUser(false);
          nav("/login");
        }
      }
    };
    fetchData();
  }, []);

  const data_map = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        fill: true,
        label: "Customer",
        data: Array.isArray(data) && data.map((item) => item.value),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Total Customer yang Menyewa",
      },
    },
  };
  const onClick = (event) => {
    try {
      const element = getElementAtEvent(chartRef.current, event)[0];

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const startDate = new Date(
        `${data[element.index].label} "1", ${currentYear}`
      );

      const endDate = new Date(`${startDate.getMonth() + 2} 1, ${currentYear}`);

      return nav("/rent", {
        state: {
          startDate: startDate,
          endDate: endDate,
        },
      });
    } catch (error) {
      console.log("wrong input");
    }
  };

  return (
    <div style={{ width: "450px" }}>
      <Line
        ref={chartRef}
        data={data_map}
        options={options}
        onClick={onClick}
      />
    </div>
  );
};

export default LineChart;
