import React, { useEffect, useContext, useState } from "react";
import { ProductContext } from "../../../contexts/ProductContext";
import { domain } from "../../../config";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";

const FetchProduct = () => {
  const nav = useNavigate();
  const token = localStorage.getItem("token");
  const { user, setUser } = useContext(UserContext);
  const { product, setProduct } = useContext(ProductContext);
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`${domain}/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await data.json();
      if (data.ok) {
        setProduct(response.prodDoc);
      } else if (!data.ok) {
        if (response.message == "jwt expired") {
          console.log("error");
          setUser(false);
          nav("/login");
        }
      }
    };
    fetchData();
  }, []);
  return <></>;
};

export default FetchProduct;
