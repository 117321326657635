import React, { useState } from "react";

import Popup from "../Popup/Popup";
import ReturnTable from "./__table/ReturnTable";

const Return = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  return (
    <div className="customer__wrapper">
      <h1 className="customer__title">Return</h1>
      {/* 
      <CustomerForm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setPopup={setPopup}
        setMsg={setInfoMsg}
        data={null}
      ></CustomerForm> */}

      <ReturnTable setIsOpen={setIsOpen} isOpen={isOpen} />
      <Popup isOpen={isPopup} setIsOpen={setPopup} infoMsg={infoMsg} />
    </div>
  );
};

export default Return;
