import React, { useContext, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { AiFillEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import CustomerPopup from "../__popup/CustomerPopup";
import Popup from "../../Popup/Popup";
const CustomerAction = ({ id, isPopup, setPopup, setInfoMsg }) => {
  const { customer, setCustomer } = useContext(CustomerContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <span>
      <CustomerPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={id}
        isPopup={isPopup}
        setPopup={setPopup}
        setMsg={setInfoMsg}
      />
      <AiFillDelete
        style={{ cursor: "pointer" }}
        onClick={(e) => setIsOpen(true)}
      />
      <Link to={`update/${id}`}>
        <AiFillEdit style={{ cursor: "pointer" }} />
      </Link>
    </span>
  );
};

export default CustomerAction;
