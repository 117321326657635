import React, { useCallback, useContext, useEffect, useState } from "react";
import RentForm from "./__form/RentForm";
import FetchProduct from "./fetchProduct/FetchProduct";
import FetchCustomer from "./fetchCustomer/FetchCustomer";
import FetchRent from "./fetchRent/FetchRent";
import { RentContext } from "../../contexts/RentContext";
import RentTable from "./__table/RentTable";
import Popup from "../Popup/Popup";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
const Rent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const { rent, setRent } = useContext(RentContext);

  const location = useLocation();

  const startDate = location.state ? location.state.startDate : "";
  const endDate = location.state ? location.state.endDate : "";

  const formatDateTime = (value) => {
    const createdAt = new Date(value.createdAt);
    const day = String(createdAt.getDate()).padStart(2, "0");
    const month = String(createdAt.getMonth() + 1).padStart(2, "0"); // Note: Month is 0-based, so we add 1
    const year = createdAt.getFullYear();
    const time = createdAt.toLocaleTimeString();
    const newValue = `${day}/${month}/${year} ${time}`;
    return newValue;
  };

  return (
    <div className="customer__wrapper">
      <FetchProduct />
      <FetchCustomer />
      <Popup />
      <h1 className="customer__title">Rent</h1>

      <RentForm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setPopup={setPopup}
        setMsg={setInfoMsg}
        data={null}
      ></RentForm>
      <Popup isOpen={isPopup} setIsOpen={setPopup} infoMsg={infoMsg} />
      <RentTable
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  );
};

export default Rent;
