import React, { useEffect, useContext, useState } from "react";
import { RentContext } from "../../../contexts/RentContext";
import { UserContext } from "../../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { domain } from "../../../config";
import { Link } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
// import RentAction from "../__action/RentAction";
import Popup from "../../Popup/Popup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

const ReturnTable = ({ setIsOpen, isOpen, startDate, endDate }) => {
  const { rent, setRent } = useContext(RentContext);
  const [isPopup, setPopup] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const [clear, setClear] = useState(false);
  const [clearBtn, setClearBtn] = useState(false);
  const [filterByStart, setFilterByStart] = useState();
  const [filterByEnd, setFilterByEnd] = useState();
  const { user, setUser } = useContext(UserContext);
  const [isPengembalian, setIsPengembalian] = useState({
    status: false,
    value: "",
    id: "",
  });
  const [filter, setFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const token = localStorage.getItem("token");
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [{ name: "Terima", format: 1 }];

  const nav = useNavigate();

  const drillData = async () => {
    setFilterByStart(
      startDate.toLocaleDateString("en-US", { format: "%Y-%m-%d" })
    );
    setFilterByEnd(endDate.toLocaleDateString("en-US", { format: "%Y-%m-%d" }));

    const fetchData = async () => {
      setPopup(true);
      setInfoMsg("Loading");
      const data = await fetch(`${domain}/customer/dashboardDrill`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          startDate: startDate,
          endDate: endDate,
        }),
      });

      const response = await data.json();
      if (data.ok) {
        setPopup(false);
        setRent(response.doc);
      } else if (!data.ok) {
        if (response.message == "jwt expired") {
          console.log("error");
          setUser(false);
          nav("/login");
        }
      }
    };
    fetchData();
  };
  const fetchData = async () => {
    setPopup(true);
    setInfoMsg("Loading");
    const data = await fetch(`${domain}/return`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const response = await data.json();
    if (data.ok) {
      setPopup(false);
      setRent(response.returnDoc);
    } else if (!data.ok) {
      if (response.message == "jwt expired") {
        console.log("error");
        setUser(false);
        nav("/login");
      }
    }
  };
  useEffect(() => {
    if (startDate && endDate) {
      drillData();
      setClearBtn(true);
    } else {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (clear) {
      fetchData();
    }
  }, [clear]);

  const handlePengembalian = (value, id) => {
    setIsPengembalian({ status: true, id: id, value: value });
    setPopup(true);
  };

  return (
    <>
      <Popup
        isOpen={isPopup}
        setIsOpen={setPopup}
        infoMsg={infoMsg}
        setInfoMsg={setInfoMsg}
        isPengembalian={isPengembalian.status}
        id={isPengembalian.id}
        value={isPengembalian.value.format}
        setIsPengembalian={setIsPengembalian}
      />
      <div style={{ display: "flex", marginTop: "10px" }}>
        <InputText
          placeholder="Keyword Search"
          style={{
            display: "block",
            marginBottom: "10px",
            marginRight: "10px",
          }}
          onInput={(e) => {
            setFilter({
              ...filter,
              global: {
                value: e.target.value,
                matchMode: FilterMatchMode.CONTAINS,
              },
            });
          }}
        />
        {!clear && clearBtn && (
          <p onClick={() => setClear(true)} style={{ cursor: "pointer" }}>
            <u>Clear Rent </u>
            Filter by {filterByStart} to {filterByEnd}
          </p>
        )}
      </div>

      <DataTable
        value={rent}
        stripedRows
        showGridlines
        filters={filter}
        dataKey="_id"
        tableStyle={{ minWidth: "70rem" }}
        paginator
        rows={10}
        removableSort
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
      >
        <Column
          field="rentNo"
          header="No Rent"
          sortable
          body={(rent) => {
            return (
              <Link to={`/rent/${rent._id}`} className="customer__name">
                {rent.rentNo}
              </Link>
            );
          }}
        ></Column>
        <Column
          field="customer.displayName"
          header="Customer"
          sortable
        ></Column>
        <Column
          field={(e) => {
            return e.product.map((p, index) => {
              return p.productID.displayName;
            });
          }}
          body={(e) => {
            return e.product.map((p, index) => {
              return p.productID.displayName + ", ";
            });
          }}
          header="Product"
          style={{ minWidth: "20rem" }}
        ></Column>
        <Column field="customer.address" sortable header="Address"></Column>
        <Column
          field="tglOut"
          body={(e) => {
            return new Date(e.tglTr).toLocaleString();
          }}
          header="Tanggal Pengembalian"
          sortable
        ></Column>
        <Column
          field="statusPengembalian"
          body={(e) => {
            return e.statusPengembalian == 0 ? (
              <Dropdown
                value={selectedCity}
                onChange={(value) => {
                  console.log(value.value);
                  handlePengembalian(value.value, e._id);
                }}
                options={cities}
                optionLabel="name"
                placeholder="Terima Barang"
                className="w-full md:w-14rem"
              />
            ) : (
              e.statusPengembalian
            );
          }}
          header="Pengembalian"
          sortable
        ></Column>
      </DataTable>
    </>
  );
};

export default ReturnTable;
