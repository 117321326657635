import React, { useState, useEffect } from "react";
import { domain } from "../../../config";
import { useParams } from "react-router-dom";
import ProductForm from "../__form/ProductForm";
import Popup from "../../Popup/Popup";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
const ProductDetail = () => {
  let { id, update } = useParams();
  const token = localStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState("");
  const [isPopup, setPopup] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`${domain}/product/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await data.json();
      setData(response);
    };
    fetchData();
  }, []);

  return (
    <div className="customer__wrapper">
      {!update && data && (
        <div>
          <h1>Detail</h1>
          <p>Display Name : {data.displayName}</p>
          <p>Deskripsi : {data.deskripsi}</p>
          <p>Quantity : {data.quantity}</p>
          <p>Unit : {data.unit}</p>
          <p>
            Harga Satuan :{" "}
            {new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(data.hargaSatuan)}
          </p>
          <TabView>
            <TabPanel header="Activity">
              <DataTable value={data.history} size="small">
                <Column field={"action"} header={"Action"} />
                <Column
                  header={"Data"}
                  field={(e) => {
                    return e.updatedFields || "";
                  }}
                  body={(e) => {
                    const jsonData = e.updatedFields;

                    // Format the fields

                    const formattedString =
                      jsonData &&
                      Object.entries(jsonData).map(([key, value]) => (
                        <span key={key}>
                          <b>{key}</b>: {value},{" "}
                        </span>
                      ));

                    return <p className="m-0">{formattedString}</p>;
                  }}
                ></Column>
                <Column
                  body={(e) => {
                    return new Date(e.createdAt).toLocaleString();
                  }}
                  header={"Created"}
                />
              </DataTable>
            </TabPanel>
            <TabPanel header="Note"></TabPanel>
          </TabView>
        </div>
      )}

      {update && data && (
        <ProductForm
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={data}
          id={id}
          setPopup={setPopup}
          setMsg={setInfoMsg}
        />
      )}
      <Popup isOpen={isPopup} setIsOpen={setPopup} infoMsg={infoMsg} />
    </div>
  );
};

export default ProductDetail;
