import React, { useContext, useEffect } from "react";
import Select from "react-select";
import { ProductContext } from "../../contexts/ProductContext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

const AddProduct = ({ productList, setProductList, handleAddElement }) => {
  const { product } = useContext(ProductContext);

  const handleRemoveElement = (index) => {
    if (productList.length > 1) {
      const updatedList = [...productList];
      updatedList.splice(index, 1);
      setProductList(updatedList);
    }
  };
  useEffect(() => {
    console.log(productList);
  }, []);

  const handleInputChange = (index, property, newValue) => {
    const updatedList = [...productList];
    updatedList[index][property] = newValue;
    setProductList(updatedList);
  };
  const formatToRupiah = (value) => {
    // Remove non-numeric characters and convert to a number
    const numericValue = parseFloat(value.replace(/[^0-9.-]/g, ""));

    if (!isNaN(numericValue)) {
      const formattedValue = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(numericValue);

      return formattedValue;
    } else {
      return "";
    }
  };

  const handleBlur = (index, property, value) => {
    // Format the value when the input loses focus
    const formattedValue = formatToRupiah(value);
    const updatedList = [...productList];
    updatedList[index][property] = formattedValue;
    setProductList(updatedList);
  };
  return (
    <>
      {productList.map((item, index) => {
        return (
          <div key={index} className="addproduct__wrapper">
            <label>Product</label>
            <Select
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: 200,
                }),
              }}
              options={product.map((p) => ({
                value: p._id,
                label: p.displayName,
                hargaSatuan: p.hargaSatuan,
              }))}
              value={{
                value: productList[index]["productID"],
                label: productList[index]["label"],
              }}
              onChange={(e) => {
                handleInputChange(index, "productID", e.value);
                handleInputChange(index, "label", e.label);
                handleInputChange(index, "hargaSatuan", e.hargaSatuan);
                handleInputChange(index, "total", null);
                handleInputChange(index, "quantity", null);
              }}
            />
            <label>Quantity</label>

            <InputNumber
              value={item.quantity}
              className="customer__input"
              onValueChange={(e) => {
                handleInputChange(index, "quantity", e.target.value);
                handleInputChange(
                  index,
                  "total",
                  e.target.value * item.hargaSatuan
                );
              }}
              useGrouping={false}
            />
            <label>Harga Satuan</label>

            <InputNumber
              style={{ width: 250 }}
              className="customer__input"
              inputId="currency-indonesia"
              value={item.hargaSatuan}
              onValueChange={(e) => {
                handleInputChange(index, "hargaSatuan", e.target.value);
                handleInputChange(
                  index,
                  "total",
                  e.target.value * item.quantity
                );
              }}
              mode="currency"
              currency="IDR"
              locale="de-DE"
            />
            <label>Total</label>

            <InputNumber
              disabled
              inputId="currency-indonesia"
              className="customer__input"
              value={item.total}
              onValueChange={(e) => {
                handleInputChange(index, "total", e.target.value);
              }}
              mode="currency"
              currency="IDR"
              locale="de-DE"
            />
            <Button
              type="button"
              size="small"
              onClick={() => handleRemoveElement(index)}
              severity="warning"
            >
              remove
            </Button>
            <Button
              style={{
                textAlign: "center",
                width: 50,
              }}
              type="button"
              onClick={() => handleAddElement()}
              severity="success"
            >
              +
            </Button>
          </div>
        );
      })}
    </>
  );
};

export default AddProduct;
