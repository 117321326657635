import React, { useContext, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { Menu } from "primereact/menu";
import { MdOutlineLogout } from "react-icons/md";
const Sidebar = () => {
  const nav = useNavigate();
  const { user, setUser } = useContext(UserContext);

  let items = [
    {
      label: "Jurnal Ana",
      style: {
        fontSize: "18px",
      },
      items: [
        {
          label: "Customer",
          icon: "pi pi-user",
          command: (e) => {
            nav("/");
          },
        },
        {
          label: "Product",
          icon: "pi pi-box",
          command: (e) => {
            nav("/product");
          },
        },
        {
          label: "Rent",
          icon: "pi pi-book",
          command: (e) => {
            nav("/rent");
          },
        },
        {
          label: "Return of Item",
          icon: "pi pi-arrow-right-arrow-left",
          command: (e) => {
            nav("/return");
          },
        },
      ],
    },
    { separator: true },
    {
      label: "Logout",
      icon: <MdOutlineLogout style={{ marginRight: "5px" }} />,
      command: () => {
        localStorage.removeItem("token");
        setUser(false);
      },
    },
  ];

  let admin_items = [
    {
      items: [
        {
          label: "List Payment",
          icon: "pi pi-paypal",
          command: (e) => {
            // Add your custom logic here for the new label
          },
        },
      ],
    },
  ];

  return (
    <div className="sidebar">
      {user.user && user.user.role.includes("Administrator") && (
        <Menu
          model={admin_items}
          // style={{ height: "10vh" }}
        />
      )}
      <Menu model={items} style={{ height: "100vh" }} />
    </div>
  );
};

export default Sidebar;
