import React, { useState } from "react";
import ProductTable from "./__table/ProductTable";
import ProductForm from "./__form/ProductForm";
import Popup from "../Popup/Popup";
import { Button } from "primereact/button";

const Product = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  return (
    <div className="customer__wrapper">
      <h1 className="customer__title">Product</h1>

      <ProductForm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={null}
        setPopup={setPopup}
        setMsg={setInfoMsg}
      ></ProductForm>
      <Popup isOpen={isPopup} setIsOpen={setPopup} infoMsg={infoMsg} />
      <ProductTable setIsOpen={setIsOpen} isOpen={isOpen} />
    </div>
  );
};

export default Product;
